<template>
  <div>
    <Navbar page="Relatórios Específicos" link="/entregaveis" nameLink="Entregáveis" />
    <div class="mx-4 my-4 md:mx-8 md:my-8">
      <div class="grid grid-cols-12 gap-6">
        <div v-if="!$store.state.user.cliente" class="col-span-12 xl:col-span-3 mb-1 mt-1">
          <router-link to="/entregaveis/especifico/empresas" class="flex flex-col items-center justify-center py-4 text-lg border-2 bg-white shadow-md rounded-md hover:bg-yellow-300">
            <span class="text-base">Adequações em andamento</span>
          </router-link>
        </div>
        <div class="col-span-12 xl:col-span-3 mb-1 mt-1">
          <router-link to="/entregaveis/especifico/atividades" class="flex flex-col items-center justify-center py-4 text-lg border-2 bg-white shadow-md rounded-md hover:bg-yellow-300">
            <span class="text-base">Mapeamento de atividades</span>
          </router-link>
        </div>
        <div class="col-span-12 xl:col-span-3 mb-1 mt-1">
          <router-link to="/entregaveis/especifico/projetos" class="flex flex-col items-center justify-center py-4 text-lg border-2 bg-white shadow-md rounded-md hover:bg-yellow-300">
            <span class="text-base">Gestão de projetos</span>
          </router-link>
        </div>
        <div class="col-span-12 xl:col-span-3 mb-1 mt-1">
          <router-link to="/entregaveis/especifico/riscos" class="flex flex-col items-center justify-center py-4 text-lg border-2 bg-white shadow-md rounded-md hover:bg-yellow-300">
            <span class="text-base">Gestão de riscos</span>
          </router-link>
        </div>
        <div class="col-span-12 xl:col-span-3 mb-1 mt-1">
          <router-link to="/entregaveis/especifico/requisicao" class="flex flex-col items-center justify-center py-4 text-lg border-2 bg-white shadow-md rounded-md hover:bg-yellow-300">
            <span class="text-base">Requisições dos titulares</span>
          </router-link>
        </div>
      </div>
  
    </div>
  </div>
</template>

<script>
export default {}
</script>